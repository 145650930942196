html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

a:hover,
a:active,
a:focus,
button:focus {
  text-decoration: none;
  box-shadow: none !important;
  outline: none; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  color: #333447;
  line-height: 1.5;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", sans-serif; }

h1, h2, h3, h4, h5, h6, p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding: 0; }

p {
  font-weight: 300; }

.weight-heavy {
  font-weight: 600; }

.weight-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-heavy {
  font-weight: 600; }

.color-light {
  color: #707079; }

.color-success {
  color: #65CF7C; }

.color-danger {
  color: #b41d19; }

.color-warning {
  color: #ffc107; }

.color-pink {
  color: #FF3366; }

.size-small {
  font-size: 0.875em; }

.text-center {
  text-align: center; }

.scroller {
  height: 100%;
  overflow-y: auto; }

.no-point-events {
  pointer-events: none !important; }

@font-face {
  font-family: "calchub-icons";
  src: url(/assets/calchub-icons.259f329f.eot);
  src: url(/assets/calchub-icons.259f329f.eot) format("eot"), url(/assets/calchub-icons.e3e506eb.woff2) format("woff2"), url(/assets/calchub-icons.b1e27190.woff) format("woff"), url(/assets/calchub-icons.ab226255.ttf) format("truetype"), url(/assets/calchub-icons.b9996ff8.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

.icon:before {
  display: inline-block;
  font-family: "calchub-icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.icon-add:before {
  content: "\EA01"; }

.icon-align-bottom:before {
  content: "\EA02"; }

.icon-align-horizontal:before {
  content: "\EA03"; }

.icon-align-left:before {
  content: "\EA04"; }

.icon-align-right:before {
  content: "\EA05"; }

.icon-align-top:before {
  content: "\EA06"; }

.icon-align-vertical:before {
  content: "\EA07"; }

.icon-arrow-down:before {
  content: "\EA08"; }

.icon-arrow-left:before {
  content: "\EA09"; }

.icon-arrow-right:before {
  content: "\EA0A"; }

.icon-arrow-up:before {
  content: "\EA0B"; }

.icon-assignment:before {
  content: "\EA0C"; }

.icon-backward:before {
  content: "\EA0D"; }

.icon-bold:before {
  content: "\EA0E"; }

.icon-calchub:before {
  content: "\EA0F"; }

.icon-caret-down:before {
  content: "\EA10"; }

.icon-center:before {
  content: "\EA11"; }

.icon-check:before {
  content: "\EA12"; }

.icon-close:before {
  content: "\EA13"; }

.icon-code:before {
  content: "\EA14"; }

.icon-color-fill:before {
  content: "\EA15"; }

.icon-color-reset:before {
  content: "\EA16"; }

.icon-currency-off:before {
  content: "\EA17"; }

.icon-currency:before {
  content: "\EA18"; }

.icon-decimal-decrease:before {
  content: "\EA19"; }

.icon-decimal-increase:before {
  content: "\EA1A"; }

.icon-delete:before {
  content: "\EA1B"; }

.icon-download:before {
  content: "\EA1C"; }

.icon-ellipse:before {
  content: "\EA1D"; }

.icon-email-outline:before {
  content: "\EA1E"; }

.icon-error:before {
  content: "\EA1F"; }

.icon-evaluate:before {
  content: "\EA20"; }

.icon-facebook:before {
  content: "\EA21"; }

.icon-filter:before {
  content: "\EA22"; }

.icon-folder:before {
  content: "\EA23"; }

.icon-format-paint:before {
  content: "\EA24"; }

.icon-forward:before {
  content: "\EA25"; }

.icon-group:before {
  content: "\EA26"; }

.icon-image:before {
  content: "\EA27"; }

.icon-info:before {
  content: "\EA28"; }

.icon-italic:before {
  content: "\EA29"; }

.icon-launch:before {
  content: "\EA2A"; }

.icon-library:before {
  content: "\EA2B"; }

.icon-linkedin:before {
  content: "\EA2C"; }

.icon-list:before {
  content: "\EA2D"; }

.icon-lock-open:before {
  content: "\EA2E"; }

.icon-lock:before {
  content: "\EA2F"; }

.icon-math:before {
  content: "\EA30"; }

.icon-menu:before {
  content: "\EA31"; }

.icon-more-vert:before {
  content: "\EA32"; }

.icon-move-tool:before {
  content: "\EA33"; }

.icon-no-evaluate:before {
  content: "\EA34"; }

.icon-output:before {
  content: "\EA35"; }

.icon-page:before {
  content: "\EA36"; }

.icon-pan-tool:before {
  content: "\EA37"; }

.icon-percent:before {
  content: "\EA38"; }

.icon-pin:before {
  content: "\EA39"; }

.icon-plot:before {
  content: "\EA3A"; }

.icon-recent:before {
  content: "\EA3B"; }

.icon-reddit:before {
  content: "\EA3C"; }

.icon-redo:before {
  content: "\EA3D"; }

.icon-remove:before {
  content: "\EA3E"; }

.icon-save-to-toolbox:before {
  content: "\EA3F"; }

.icon-search:before {
  content: "\EA40"; }

.icon-select-tool:before {
  content: "\EA41"; }

.icon-share:before {
  content: "\EA42"; }

.icon-stack:before {
  content: "\EA43"; }

.icon-star-border:before {
  content: "\EA44"; }

.icon-sync:before {
  content: "\EA45"; }

.icon-table:before {
  content: "\EA46"; }

.icon-text-align-center:before {
  content: "\EA47"; }

.icon-text-align-left:before {
  content: "\EA48"; }

.icon-text-align-right:before {
  content: "\EA49"; }

.icon-text-color:before {
  content: "\EA4A"; }

.icon-text:before {
  content: "\EA4B"; }

.icon-to-back:before {
  content: "\EA4C"; }

.icon-to-front:before {
  content: "\EA4D"; }

.icon-tumblr:before {
  content: "\EA4E"; }

.icon-twitter:before {
  content: "\EA4F"; }

.icon-underline:before {
  content: "\EA50"; }

.icon-undo:before {
  content: "\EA51"; }

.icon-ungroup:before {
  content: "\EA52"; }

.icon-units:before {
  content: "\EA53"; }

.icon-unstack:before {
  content: "\EA54"; }

.icon-vpn-key:before {
  content: "\EA55"; }

.icon-warning:before {
  content: "\EA56"; }

.icon-zoom-in:before {
  content: "\EA57"; }

.icon-zoom-out:before {
  content: "\EA58"; }

.icon-zoom-tool:before {
  content: "\EA59"; }

.icon {
  line-height: 1px; }

.icon-15x {
  font-size: 1.5em; }

.icon.spin:before {
  -webkit-animation: spin 1.5s infinite;
          animation: spin 1.5s infinite; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Thin.ad538a69.woff2) format("woff2"), url(/assets/Roboto-Thin.d3b47375.woff) format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Thin';
  src: url(/assets/Roboto-Thin.ad538a69.woff2) format("woff2"), url(/assets/Roboto-Thin.d3b47375.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-ThinItalic.5b4a33e1.woff2) format("woff2"), url(/assets/Roboto-ThinItalic.8a96edbb.woff) format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url(/assets/Roboto-ThinItalic.5b4a33e1.woff2) format("woff2"), url(/assets/Roboto-ThinItalic.8a96edbb.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Light.d26871e8.woff2) format("woff2"), url(/assets/Roboto-Light.c73eb1ce.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url(/assets/Roboto-Light.d26871e8.woff2) format("woff2"), url(/assets/Roboto-Light.c73eb1ce.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-LightItalic.e8eaae90.woff2) format("woff2"), url(/assets/Roboto-LightItalic.13efe6cb.woff) format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/assets/Roboto-LightItalic.e8eaae90.woff2) format("woff2"), url(/assets/Roboto-LightItalic.13efe6cb.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Regular.73f0a88b.woff2) format("woff2"), url(/assets/Roboto-Regular.35b07eb2.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url(/assets/Roboto-Regular.73f0a88b.woff2) format("woff2"), url(/assets/Roboto-Regular.35b07eb2.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-RegularItalic.4357beb8.woff2) format("woff2"), url(/assets/Roboto-RegularItalic.f5902d5e.woff) format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-RegularItalic';
  src: url(/assets/Roboto-RegularItalic.4357beb8.woff2) format("woff2"), url(/assets/Roboto-RegularItalic.f5902d5e.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Medium.90d16760.woff2) format("woff2"), url(/assets/Roboto-Medium.1d659482.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: url(/assets/Roboto-Medium.90d16760.woff2) format("woff2"), url(/assets/Roboto-Medium.1d659482.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-MediumItalic.13ec0eb5.woff2) format("woff2"), url(/assets/Roboto-MediumItalic.83e114c3.woff) format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url(/assets/Roboto-MediumItalic.13ec0eb5.woff2) format("woff2"), url(/assets/Roboto-MediumItalic.83e114c3.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Bold.b52fac2b.woff2) format("woff2"), url(/assets/Roboto-Bold.50d75e48.woff) format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url(/assets/Roboto-Bold.b52fac2b.woff2) format("woff2"), url(/assets/Roboto-Bold.50d75e48.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-BoldItalic.94008e69.woff2) format("woff2"), url(/assets/Roboto-BoldItalic.4fe0f73c.woff) format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url(/assets/Roboto-BoldItalic.94008e69.woff2) format("woff2"), url(/assets/Roboto-BoldItalic.4fe0f73c.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-Black.59eb3601.woff2) format("woff2"), url(/assets/Roboto-Black.313a6563.woff) format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Black';
  src: url(/assets/Roboto-Black.59eb3601.woff2) format("woff2"), url(/assets/Roboto-Black.313a6563.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  src: url(/assets/Roboto-BlackItalic.f75569f8.woff2) format("woff2"), url(/assets/Roboto-BlackItalic.cc2fadc3.woff) format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url(/assets/Roboto-BlackItalic.f75569f8.woff2) format("woff2"), url(/assets/Roboto-BlackItalic.cc2fadc3.woff) format("woff"); }

.alert {
  background-color: #eeeeee;
  border: 1px solid #d1d5da;
  border-radius: .25rem;
  margin-bottom: .5em;
  padding: .75rem 1.25rem;
  position: relative; }
  .alert a {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer; }
  .alert.alert-success {
    background-color: #c4e9d7;
    border-color: #b1e2ca;
    color: #2f7f57; }
  .alert.alert-danger {
    background-color: #f9d4d3;
    border-color: #f3a8a6;
    color: #5a0f0d; }
  .alert.alert-info {
    background-color: #b8daff;
    border-color: #85c0ff;
    color: #002752; }
  .alert.alert-warning {
    background-color: #fff4d3;
    border-color: #ffe7a0;
    color: #543f00; }

.avatar {
  background: #707079;
  border-radius: 50%;
  color: rgba(238, 238, 238, 0.85);
  display: inline-block;
  font-weight: 300;
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  font-size: 0.8rem;
  height: 1.6rem;
  width: 1.6rem; }
  .avatar.avatar-xs {
    font-size: 0.4rem;
    height: 0.8rem;
    width: 0.8rem; }
  .avatar.avatar-sm {
    font-size: 0.6rem;
    height: 1.2rem;
    width: 1.2rem; }
  .avatar.avatar-lg {
    font-size: 1.2rem;
    height: 2.4rem;
    width: 2.4rem; }
  .avatar.avatar-xl {
    font-size: 1.6rem;
    height: 3.2rem;
    width: 3.2rem; }
  .avatar img {
    border-radius: 50%;
    height: 100%;
    position: relative;
    width: 100%; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: .5em 1em;
  font-size: 1rem;
  border-radius: .25em;
  cursor: pointer; }
  .btn i {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  .btn span {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  .btn i:before {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  .btn.disabled {
    opacity: 0.5;
    pointer-events: none; }
  .btn.btn-sm {
    font-size: 0.8rem;
    padding: 0.4em 0.9em; }
  .btn.btn-block {
    box-sizing: border-box;
    display: block;
    width: 100%; }

.btn {
  color: #333447;
  background-color: white;
  border: 1px solid #d1d5da; }
  .btn:hover span {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .btn.active {
    background-color: #54C08A; }
    .btn.active span {
      -webkit-transform: scale(1);
              transform: scale(1); }

.btn.btn-primary {
  color: #fff;
  background-color: #54C08A;
  border-color: #43b77d; }
  .btn.btn-primary:hover {
    background-color: #43b77d; }

.btn.btn-clear {
  background-color: transparent;
  border-color: transparent; }
  .btn.btn-clear:hover {
    background-color: white;
    border-color: #d1d5da; }

.btn.btn-success {
  color: #fff;
  background-color: #54C08A;
  border-color: #2AB26E; }
  .btn.btn-success:hover {
    background-color: #2AB26E; }

.btn.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f; }
  .btn.btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #c12e2a; }

.btn.btn-link {
  background-color: transparent;
  border-color: transparent; }
  .btn.btn-link:hover {
    text-decoration: underline;
    color: #29292f; }

.btn.btn-icon {
  padding: 0.3rem;
  line-height: 0;
  font-size: 1.5rem; }
  .btn.btn-icon i {
    font-size: inherit; }
  .btn.btn-icon:hover i {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  .btn.btn-icon:hover i:before {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  .btn.btn-icon:focus {
    color: #65CF7C; }
  .btn.btn-icon.active {
    background-color: #65CF7C; }
  .btn.btn-icon.btn-xs {
    font-size: 0.75rem; }
  .btn.btn-icon.btn-sm {
    font-size: 1rem; }

.toolbar-button-divider {
  height: 20px;
  margin-left: 5px;
  width: 5px;
  border-left: 1px solid grey; }

.calc-card {
  border-radius: 3.5px;
  position: relative;
  margin: 10px;
  flex: 1 1 280px;
  align-self: flex-start;
  background-color: white;
  box-shadow: 0 0 5px rgba(57, 70, 78, 0.4); }
  .calc-card .launch-button {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; }
  .calc-card .visibility-icon {
    position: absolute;
    top: 5px;
    left: 5px; }
  .calc-card:hover .launch-button {
    opacity: 1; }
  .calc-card .card-body {
    position: relative; }
    .calc-card .card-body .card-title {
      font: normal normal 400 14px/20px Roboto, sans-serif;
      letter-spacing: -.1px; }
    .calc-card .card-body .card-subtitle {
      justify-content: flex-start; }
    .calc-card .card-body .card-tools {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      padding: 0 7px; }
  .calc-card .new-tag {
    background-color: #2AB26E;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -8px;
    left: -8px;
    color: white;
    border-radius: 25px;
    box-shadow: 0 0 2px rgba(57, 70, 78, 0.4); }
  .calc-card.calc-card--processing {
    opacity: 0.5;
    pointer-events: none; }

.calc-card.new-calc-card {
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px dashed #30c2ff;
  opacity: .5;
  min-height: 200px; }
  .calc-card.new-calc-card:hover {
    opacity: 1; }

.calc-card.invisible {
  visibility: hidden; }

.calcs-list {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-content: stretch;
  cursor: default;
  background: #f8f8f8;
  padding-top: 1rem; }

.card {
  background: #fff;
  border: 1px solid #d1d5da;
  border-radius: 0.3125em;
  display: flex;
  flex-direction: column; }
  .card .card-header,
  .card .card-body,
  .card .card-footer {
    padding: 0.5em;
    padding-bottom: 0; }
    .card .card-header:last-child,
    .card .card-body:last-child,
    .card .card-footer:last-child {
      padding-bottom: 0.5em; }
  .card .card-body {
    flex: 1 1 auto; }
  .card .card-image {
    padding-top: 0.5em; }
    .card .card-image:first-child {
      padding-top: 0; }
      .card .card-image:first-child img {
        border-top-left-radius: 0.3125em;
        border-top-right-radius: 0.3125em; }
    .card .card-image:last-child img {
      border-bottom-left-radius: 0.3125em;
      border-bottom-right-radius: 0.3125em; }

.color-picker-wrapper {
  position: absolute; }
  .color-picker-wrapper.left-top {
    right: 100%;
    top: 0;
    margin-right: .25rem; }
  .color-picker-wrapper.left-center {
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-right: .25rem; }
  .color-picker-wrapper.left-bottom {
    right: 100%;
    bottom: 0;
    margin-right: .25rem; }
  .color-picker-wrapper.top-left {
    left: 0;
    bottom: 100%;
    margin-bottom: .25rem; }
  .color-picker-wrapper.top-center {
    left: 50%;
    bottom: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: .25rem; }
  .color-picker-wrapper.top-right {
    right: 0;
    bottom: 100%;
    margin-bottom: .25rem; }
  .color-picker-wrapper.right-top {
    left: 100%;
    top: 0;
    margin-left: .25rem; }
  .color-picker-wrapper.right-center {
    left: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    margin-left: .25rem; }
  .color-picker-wrapper.right-bottom {
    left: 100%;
    bottom: 0;
    margin-left: .25rem; }
  .color-picker-wrapper.bottom-left {
    top: 100%;
    left: 0;
    margin-top: .25rem; }
  .color-picker-wrapper.bottom-center {
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-top: .25rem; }
  .color-picker-wrapper.bottom-right {
    top: 100%;
    right: 0;
    margin-top: .25rem; }

.dropdown {
  position: relative;
  display: inline-block; }
  .dropdown.bottom .arrow {
    border-bottom: 5px solid #202632;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: -6px;
    content: "";
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .dropdown.left .arrow {
    content: "";
    position: absolute;
    top: 50%;
    left: -6px;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #202632;
    border-top: 5px solid transparent;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .dropdown.bottom.align-left .dropdown-menu {
    top: calc(100% + 5px);
    left: 0; }
  .dropdown.bottom.align-right .dropdown-menu {
    top: calc(100% + 5px);
    right: 0; }
  .dropdown.bottom.align-center .dropdown-menu {
    top: calc(100% + 5px);
    left: -50%; }
  .dropdown.right .dropdown-menu {
    top: 0;
    right: 5px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    margin-left: 10px; }
  .dropdown.left .dropdown-menu {
    top: 0;
    left: -5px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .dropdown.left.align-bottom .dropdown-menu {
    top: auto;
    bottom: 0;
    left: -5px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .dropdown .dropdown-toggle {
    cursor: pointer; }
  .dropdown .dropdown-menu {
    display: inline-block;
    position: absolute;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 0.8rem;
    color: #fff;
    text-align: left;
    background-color: #202632;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem; }
  .dropdown .dropdown-menu-item {
    position: relative;
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .dropdown .dropdown-menu-item:hover {
      color: #65CF7C; }
      .dropdown .dropdown-menu-item:hover.danger {
        color: #b41d19; }
    .dropdown .dropdown-menu-item a {
      color: inherit;
      text-decoration: inherit; }
      .dropdown .dropdown-menu-item a:after {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
  .dropdown .dropdown-menu-item--disabled {
    opacity: 0.75;
    pointer-events: none; }
  .dropdown .dropdown-menu-item-divider {
    height: 5px;
    border-bottom: 1px solid #686868;
    margin-bottom: 5px; }

.empty {
  background: #f7f8f9;
  border-radius: 0.3125em;
  color: #707079;
  text-align: center;
  padding: 3.2em 1.6em; }
  .empty .empty-icon i:before {
    font-size: 3rem; }
  .empty .empty-image {
    padding: 0 1em;
    margin-bottom: 0.5em;
    max-width: 300px; }
    .empty .empty-image img {
      width: 100%;
      box-shadow: 0 0 5px rgba(57, 70, 78, 0.4); }
  .empty .empty-title,
  .empty .empty-subtitle {
    margin: 0 auto 0.5em; }
  .empty .empty-action {
    margin-top: 1em; }

.input-group {
  position: relative;
  width: 100%; }
  .input-group.input-group--horizontal {
    display: flex;
    align-items: center; }
    .input-group.input-group--horizontal label {
      margin-bottom: 0;
      margin-right: .35em; }
  .input-group.disabled {
    pointer-events: none;
    opacity: 0.75; }
  .input-group.input-group--sm {
    font-size: 0.8rem; }
  .input-group.input-group-lg {
    font-size: 1.5rem; }
  .input-group.input-group--has-error .input-field {
    border-color: #b41d19; }
  .input-group label {
    display: block;
    text-transform: uppercase;
    font-size: .75em;
    margin-bottom: .35em; }
  .input-group textarea {
    resize: vertical; }
  .input-group .input-field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: .5em .75em;
    line-height: 1.25;
    color: #464a4c;
    background: #fff none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .input-group .input-field:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: #5cb3fd;
    outline: 0; }
  .input-group .input-addon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 33px;
    margin-left: .5rem;
    padding: 4px;
    box-sizing: border-box; }
  .input-group .input-group-help-text {
    line-height: 1.2; }
    .input-group .input-group-help-text.input-group-help-text--danger {
      color: #b41d19; }
  .input-group .radio-option label {
    display: inline-block;
    margin-left: 0.75em; }

.input-group-with-button {
  display: flex; }
  .input-group-with-button .btn {
    border-radius: .25rem 0 0 .25rem; }
  .input-group-with-button .input-group {
    margin-bottom: 0;
    flex: 1; }
    .input-group-with-button .input-group .input-field {
      border-radius: 0 .25rem .25rem 0; }

.input-group.checkbox-group {
  display: flex;
  align-items: center; }
  .input-group.checkbox-group input {
    margin-right: 1em;
    cursor: pointer; }
  .input-group.checkbox-group label {
    margin-bottom: 0;
    cursor: pointer; }
    .input-group.checkbox-group label:hover {
      color: black; }

select {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px); }

.question-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.vote-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem; }
  .vote-viewer .icon:active {
    color: rgba(0, 0, 0, 0.3); }

.username-link {
  color: #54C08A; }
  .username-link:visited {
    color: #54C08A; }

hr {
  display: block;
  position: relative;
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: 1px solid #eeeeee;
  height: 1px;
  margin: 1.5em 0;
  text-align: center; }

hr:after {
  content: attr(data-content);
  display: inline-block;
  font-weight: bold;
  padding: 0 0.25em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.laptop-gallery {
  z-index: 0;
  position: relative;
  width: 100%; }
  .laptop-gallery .desktop-image {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 1s; }
    .laptop-gallery .desktop-image.desktop-image--show {
      opacity: 1; }
  .laptop-gallery .laptop-gradient {
    background-image: linear-gradient(transparent, rgba(112, 156, 222, 0.26) 41.6%, rgba(112, 156, 222, 0.4) 71.43%, transparent);
    pointer-events: none;
    position: absolute;
    left: 50%;
    bottom: -250px;
    width: 100vw;
    height: 1000px;
    will-change: opacity;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 100%;
    -webkit-transform: translateX(-50vw);
    transform: translateX(-50vw); }

.ch-loader {
  background: #f7f8f9;
  background: radial-gradient(circle, #f7f8f9 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-animation: fadein 0.3s;
          animation: fadein 0.3s; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%; }

.menu {
  box-shadow: 0 0.2rem 0.5rem rgba(17, 17, 17, 0.3);
  border-radius: 0.3125em;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 0.4rem; }
  .menu .menu-item {
    margin-top: 0;
    padding: 0 0.4rem;
    position: relative;
    text-decoration: none; }
    .menu .menu-item > a {
      border-radius: 0.3125em;
      color: inherit;
      display: block;
      margin: 0 -0.4rem;
      padding: 0.2rem 0.4rem;
      text-decoration: none; }
    .menu .menu-item + .menu-item {
      margin-top: 0.2rem; }

.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); }
  .modal-wrap .modal {
    top: 20vh;
    left: 50vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.modal {
  background: #FCFDFD;
  border: 1px solid #E4E6E8;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.15);
  color: #47506E;
  overflow: hidden;
  padding: 20px;
  padding-top: 30px;
  position: fixed;
  width: 20rem; }
  .modal .modal-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin: -10px 0 1rem 0;
    padding-right: 40px; }
  .modal .close-button {
    position: absolute;
    right: 10px;
    top: 10px; }
  .modal .modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end; }

.share-calc-modal {
  width: 25rem; }

.social-share-buttons {
  position: relative;
  width: 100%; }
  .social-share-buttons .social-share-scroller {
    overflow-x: scroll;
    padding-right: 60px;
    width: 100%;
    white-space: nowrap; }
    .social-share-buttons .social-share-scroller::-webkit-scrollbar {
      width: 0 !important; }
  .social-share-buttons .right-scroll-gradient {
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
    content: ' ';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px; }

.page-container {
  width: 100%;
  height: 100%; }
  .page-container.with-toolbar {
    padding-top: 55px; }
  .page-container .page-content {
    height: 100%; }
  .page-container.page-scroll .page-content {
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }

.popover {
  position: relative;
  display: inline-block; }
  .popover .popover-menu-wrapper {
    position: absolute;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 1; }
    .popover .popover-menu-wrapper.popover--top {
      bottom: 100%;
      left: 50%;
      -webkit-transform: translate(-50%, 50%) scale(0);
              transform: translate(-50%, 50%) scale(0);
      padding-bottom: .25rem; }
    .popover .popover-menu-wrapper.popover--right {
      top: 50%;
      left: 100%;
      -webkit-transform: translate(-50%, -50%) scale(0);
              transform: translate(-50%, -50%) scale(0);
      padding-left: .25rem; }
    .popover .popover-menu-wrapper.popover--bottom {
      top: 100%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
              transform: translate(-50%, -50%) scale(0);
      padding-top: .25rem; }
    .popover .popover-menu-wrapper.popover--left {
      top: 50%;
      right: 100%;
      -webkit-transform: translate(50%, -50%) scale(0);
              transform: translate(50%, -50%) scale(0);
      padding-right: .25rem; }
  .popover:hover .popover-menu-wrapper.popover--top {
    -webkit-transform: translate(-50%, 0%) scale(1);
            transform: translate(-50%, 0%) scale(1); }
  .popover:hover .popover-menu-wrapper.popover--right {
    -webkit-transform: translate(0%, -50%) scale(1);
            transform: translate(0%, -50%) scale(1); }
  .popover:hover .popover-menu-wrapper.popover--bottom {
    -webkit-transform: translate(-50%, 0%) scale(1);
            transform: translate(-50%, 0%) scale(1); }
  .popover:hover .popover-menu-wrapper.popover--left {
    -webkit-transform: translate(0%, -50%) scale(1);
            transform: translate(0%, -50%) scale(1); }
  .popover .popover-menu {
    background-color: white;
    border-radius: 0.25rem;
    border-width: 1px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding-top: .5rem;
    padding-bottom: .5rem; }
    .popover .popover-menu.popover-menu--error {
      border-left: 3px solid red; }
  .popover .popover-menu-item {
    white-space: nowrap;
    padding: .25rem .5rem;
    cursor: pointer; }
    .popover .popover-menu-item:hover {
      background-color: #f7f8f9; }

.calc-preview-viewport {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f8f8f8; }
  .calc-preview-viewport .calc-preview-document {
    position: relative;
    -webkit-transform-origin: left;
            transform-origin: left; }
    .calc-preview-viewport .calc-preview-document .layer {
      position: absolute; }
    .calc-preview-viewport .calc-preview-document .expr {
      white-space: nowrap; }
    .calc-preview-viewport .calc-preview-document .image img {
      height: 100%;
      width: 100%; }
    .calc-preview-viewport .calc-preview-document .data-table-layer {
      background-color: white; }

.progress-bar {
  height: 3px;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .progress-bar .progress-bar__line {
    background-color: #FF3366;
    height: 100%;
    width: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .progress-bar.loading .progress-bar__line {
    -webkit-animation: loading 200ms;
            animation: loading 200ms; }
  .progress-bar.done-loading .progress-bar__line {
    -webkit-animation: done-loading 400ms;
            animation: done-loading 400ms; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); } }

@keyframes loading {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); } }

@-webkit-keyframes done-loading {
  0% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes done-loading {
  0% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

.resizable-container {
  position: relative;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.6); }

.tabs {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .tabs .tabs__links {
    display: flex;
    border-bottom: 1px solid #eeeeee; }
  .tabs .tabs__tab-link {
    border-bottom: 2px solid transparent;
    color: #707079;
    font-size: 0.9em;
    margin-right: 1em;
    padding: 1em 0.5em;
    text-align: center;
    transition: .2s ease;
    cursor: pointer; }
    .tabs .tabs__tab-link:hover {
      border-bottom-color: #d1d5da;
      color: #24292e;
      text-decoration: none; }
    .tabs .tabs__tab-link.tab-link--active {
      border-bottom-color: #FF3366;
      color: #24292e;
      font-weight: 600; }
  .tabs .tab-content {
    overflow-y: auto;
    flex: 1; }

.vtabs .vtabs__link {
  border-bottom-width: 1px;
  color: #707079;
  cursor: pointer;
  transition: .2s ease;
  padding: .5rem;
  padding-left: 0.75rem;
  position: relative; }
  .vtabs .vtabs__link:before {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 3px; }
  .vtabs .vtabs__link:hover {
    color: #24292e; }
    .vtabs .vtabs__link:hover:before {
      background-color: #d1d5da; }
  .vtabs .vtabs__link.vtabs__link--active {
    color: #24292e;
    font-weight: 600; }
    .vtabs .vtabs__link.vtabs__link--active:before {
      background-color: #FF3366; }
  .vtabs .vtabs__link:last-child {
    border-bottom-width: 0; }

.vtabs .vtabs__link--header {
  background-color: #edf2f7;
  border-bottom-width: 1px;
  font-size: 0.85rem;
  font-weight: 600;
  padding: .5rem;
  padding-left: 0.75rem; }

.tag__remove-button:hover svg {
  fill: #ffffff; }

#toolbar {
  align-items: center;
  background: #202632;
  box-shadow: 0 0 5px rgba(57, 70, 78, 0.4);
  position: fixed;
  display: flex;
  top: 0;
  height: 55px;
  width: 100%;
  z-index: 1; }
  #toolbar .group {
    display: flex;
    align-items: center; }
    #toolbar .group.left-group {
      margin-right: auto;
      height: 100%; }
    #toolbar .group.right-group {
      margin-left: auto;
      width: 400px;
      display: flex;
      justify-content: flex-end; }
  #toolbar .toolbar__left-group {
    display: flex;
    align-items: center;
    margin-right: auto;
    width: 400px; }
  #toolbar .toolbar__right-group {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 400px;
    justify-content: flex-end; }
  #toolbar .dashboard-link {
    cursor: pointer;
    display: flex;
    height: 70%;
    margin: 0 20px 0 10px; }
    #toolbar .dashboard-link .logo {
      height: 100%;
      max-width: 100px; }
  #toolbar .beta-banner-wrapper {
    cursor: pointer;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: 0.4s;
    width: 60px;
    -webkit-backface-visibility: hidden; }
    #toolbar .beta-banner-wrapper .beta-ribben {
      align-items: center;
      background-color: #54C08A;
      bottom: 0;
      color: white;
      display: flex;
      height: 120%;
      justify-content: center;
      left: 0;
      position: absolute;
      -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      width: 20px; }
      #toolbar .beta-banner-wrapper .beta-ribben span {
        font-size: 0.85rem;
        font-weight: 600;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        -webkit-transform-origin: center;
                transform-origin: center;
        text-transform: uppercase; }
    #toolbar .beta-banner-wrapper:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  #toolbar .user-avatar {
    overflow: hidden;
    border-radius: 45px;
    height: 40px;
    width: 40px; }
  #toolbar .calc-title {
    display: flex;
    align-items: baseline;
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 100;
    cursor: text; }
    #toolbar .calc-title .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;
      display: inline-block;
      overflow: hidden;
      font-weight: 400; }
    #toolbar .calc-title .calc-access-label {
      font-size: 12px;
      margin-left: .5em;
      font-weight: 400; }
  #toolbar .calc-title-form {
    display: flex;
    align-items: center; }
    #toolbar .calc-title-form input {
      background: transparent;
      border: none;
      border-bottom: 1px dashed;
      font-size: 18px;
      color: #a0a0a0; }
      #toolbar .calc-title-form input:focus {
        outline: none; }
  #toolbar .toolbar-button {
    height: 55px;
    padding: 7px;
    box-sizing: border-box;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    border-bottom: 3px solid transparent;
    display: flex;
    align-items: center;
    color: white; }
    #toolbar .toolbar-button svg {
      width: 40px; }
    #toolbar .toolbar-button .down-arrow {
      width: 24px; }
    #toolbar .toolbar-button .icon-fill {
      fill: #fff; }
    #toolbar .toolbar-button:hover {
      border-bottom: 3px solid #65CF7C;
      color: #65CF7C; }
      #toolbar .toolbar-button:hover .icon-fill {
        fill: #65CF7C; }
    #toolbar .toolbar-button.selected {
      border-bottom: 3px solid #65CF7C;
      color: #65CF7C; }
      #toolbar .toolbar-button.selected .icon-fill {
        fill: #65CF7C; }
    #toolbar .toolbar-button.disabled {
      cursor: default;
      opacity: 0.75;
      pointer-events: none; }
  #toolbar .saving-status {
    color: white;
    font-weight: 100;
    display: flex;
    align-items: center;
    margin-right: .5em; }

.toolbar-link {
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  height: 100%;
  margin-right: .1rem;
  padding: 0.5rem;
  white-space: nowrap; }
  .toolbar-link.current-link {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none; }
  .toolbar-link:hover {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none; }
  .toolbar-link:visited {
    color: white; }

#tooltips {
  position: relative; }

.mp-tooltip {
  background-color: rgba(0, 0, 0, 0.95);
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  pointer-events: none;
  max-width: 250px; }
  .mp-tooltip.arrow-top-center::before {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.95); }
  .mp-tooltip.arrow-right-center::before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    right: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgba(0, 0, 0, 0.95); }
  .mp-tooltip.arrow-bottom-center::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.95); }
  .mp-tooltip.arrow-left-center::before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.95); }

.tooltip-style {
  background-color: rgba(0, 0, 0, 0.95);
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  position: absolute;
  padding: 5px;
  border-radius: 3px;
  /*** top styling ***/
  /*** right styling ****/
  /*** bottom styling ****/
  /*** left styling ****/ }
  .tooltip-style.arrow-top-left::before {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-top-center::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-top-right::before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-right-top::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-right-center::before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-right-bottom::before {
    content: "";
    position: absolute;
    bottom: 5px;
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-bottom-left::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-bottom-center::before {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-bottom-right::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-left-top::before {
    content: "";
    position: absolute;
    top: 5px;
    right: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-left-center::before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    right: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgba(0, 0, 0, 0.95); }
  .tooltip-style.arrow-left-bottom::before {
    content: "";
    position: absolute;
    bottom: 5px;
    right: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgba(0, 0, 0, 0.95); }

.dot-positioner {
  position: absolute;
  width: 1rem;
  height: 1rem; }
  .dot-positioner.dot--top-left {
    top: 0;
    left: 0;
    -webkit-transform: translate(-30%, -30%);
            transform: translate(-30%, -30%); }
  .dot-positioner.dot--top-center {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%); }
  .dot-positioner.dot--top-right {
    top: 0;
    left: 100%;
    -webkit-transform: translate(-70%, -30%);
            transform: translate(-70%, -30%); }
  .dot-positioner.dot--center-right {
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-60%, -50%);
            transform: translate(-60%, -50%); }
  .dot-positioner.dot--bottom-right {
    top: 100%;
    left: 100%;
    -webkit-transform: translate(-70%, -70%);
            transform: translate(-70%, -70%); }
  .dot-positioner.dot--bottom-center {
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%); }
  .dot-positioner.dot--bottom-left {
    top: 100%;
    left: 0;
    -webkit-transform: translate(-30%, -70%);
            transform: translate(-30%, -70%); }
  .dot-positioner.dot--center-left {
    top: 50%;
    left: 0;
    -webkit-transform: translate(-40%, -50%);
            transform: translate(-40%, -50%); }

.tutorial-anchor {
  -webkit-animation: tutorial-anchor-pulse 2s infinite;
          animation: tutorial-anchor-pulse 2s infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes tutorial-anchor-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 179, 237, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(101, 207, 124, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(101, 207, 124, 0); } }

@keyframes tutorial-anchor-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 179, 237, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(101, 207, 124, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(101, 207, 124, 0); } }

.tutorial-dot {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: #63b3ed;
  -webkit-animation: dot-pulse 2s infinite;
          animation: dot-pulse 2s infinite;
  cursor: pointer; }

@-webkit-keyframes dot-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 179, 237, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(101, 207, 124, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(101, 207, 124, 0); } }

@keyframes dot-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 179, 237, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(101, 207, 124, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(101, 207, 124, 0); } }

.tip-list {
  background-color: #F6F8F9;
  border-radius: 0.5rem;
  border-width: 1px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-top: .5rem;
  position: absolute;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  top: 0;
  left: 0; }
  .tip-list.tip-list--open {
    -webkit-transform: translate(-100%, -100%) scale(1);
            transform: translate(-100%, -100%) scale(1); }
  .tip-list .tip-list-scroller {
    max-height: 18rem;
    min-width: 20rem;
    overflow-y: auto; }
    .tip-list .tip-list-scroller::-webkit-scrollbar {
      width: 0 !important; }
  .tip-list .tip-list-item {
    border-bottom-width: 1px;
    cursor: pointer; }
    .tip-list .tip-list-item .tip-list-item-content {
      overflow: hidden;
      transition: height 0.5s ease; }
    .tip-list .tip-list-item:hover {
      background-color: #f0f3f5; }
    .tip-list .tip-list-item:last-child {
      border-bottom-width: 0; }
  .tip-list .tip-list__footer {
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 1.5em 1em 0.25em 1em;
    position: absolute;
    width: 100%;
    font-size: 0.8em;
    color: #666666; }

.popover-tip {
  background-color: white;
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 16rem;
  pointer-events: auto;
  position: absolute;
  padding: 1rem;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s; }
  .popover-tip.popover-tip--top-left {
    bottom: 100%;
    left: 0;
    -webkit-transform: translate(-50%, 50%) scale(0);
            transform: translate(-50%, 50%) scale(0); }
    .popover-tip.popover-tip--top-left.popover-tip--open {
      -webkit-transform: translate(-100%, 0%) scale(1);
              transform: translate(-100%, 0%) scale(1); }
  .popover-tip.popover-tip--top-center {
    bottom: 100%;
    left: 50%;
    margin-bottom: .25rem;
    -webkit-transform: translate(-50%, 50%) scale(0);
            transform: translate(-50%, 50%) scale(0); }
    .popover-tip.popover-tip--top-center.popover-tip--open {
      -webkit-transform: translate(-50%, 0%) scale(1);
              transform: translate(-50%, 0%) scale(1); }
  .popover-tip.popover-tip--top-right {
    bottom: 100%;
    left: 100%;
    -webkit-transform: translate(-50%, 50%) scale(0);
            transform: translate(-50%, 50%) scale(0); }
    .popover-tip.popover-tip--top-right.popover-tip--open {
      -webkit-transform: translate(0%, 0%) scale(1);
              transform: translate(0%, 0%) scale(1); }
  .popover-tip.popover-tip--center-right {
    top: 50%;
    left: 100%;
    margin-left: .25rem;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0); }
    .popover-tip.popover-tip--center-right.popover-tip--open {
      -webkit-transform: translate(0%, -50%) scale(1);
              transform: translate(0%, -50%) scale(1); }
  .popover-tip.popover-tip--center-left {
    margin-right: .25rem;
    top: 50%;
    right: 100%;
    -webkit-transform: translate(50%, -50%) scale(0);
            transform: translate(50%, -50%) scale(0); }
    .popover-tip.popover-tip--center-left.popover-tip--open {
      -webkit-transform: translate(0%, -50%) scale(1);
              transform: translate(0%, -50%) scale(1); }
  .popover-tip.popover-tip--bottom-left {
    top: 100%;
    left: 0;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0); }
    .popover-tip.popover-tip--bottom-left.popover-tip--open {
      -webkit-transform: translate(-100%, 0%) scale(1);
              transform: translate(-100%, 0%) scale(1); }
  .popover-tip.popover-tip--bottom-center {
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    margin-top: .25rem; }
    .popover-tip.popover-tip--bottom-center.popover-tip--open {
      -webkit-transform: translate(-50%, 0%) scale(1);
              transform: translate(-50%, 0%) scale(1); }
  .popover-tip.popover-tip--bottom-right {
    top: 100%;
    left: 100%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0); }
    .popover-tip.popover-tip--bottom-right.popover-tip--open {
      -webkit-transform: translate(0%, 0%) scale(1);
              transform: translate(0%, 0%) scale(1); }

.spotlight-tip__spotlight {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6);
  position: absolute;
  top: -20rem;
  left: -10rem;
  right: -10rem;
  bottom: -5rem;
  border-radius: 9999px; }

.user-avatar {
  position: relative; }

.user-avatar__admin-badge {
  background-color: #ffc107;
  color: white;
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 0 2px; }

.user-avatar__image img {
  border-radius: 3px; }

.copy-to-clipboard-button {
  position: relative; }
  .copy-to-clipboard-button .copy-to-clipboard-button__tip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: white;
    background-color: black;
    padding: .25em;
    font-size: 0.85rem;
    border-radius: .25em; }
    .copy-to-clipboard-button .copy-to-clipboard-button__tip:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.95); }

a {
  color: #0366d6;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }
  a:visited {
    color: #551a8b; }
  a.no-decoration {
    color: inherit;
    text-decoration: inherit; }

body {
  overflow: hidden;
  background-color: #f7f8f9; }
  body.cursor-loading {
    cursor: progress !important;
    pointer-events: none; }
  body.resize-tool {
    cursor: nwse-resize !important; }

#root {
  height: 100%;
  width: 100%; }
